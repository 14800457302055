.gallery {
    display: grid;
    grid-gap: 10px;
    margin: 9rem 2rem;
  }
  
  .gallery img {
    object-fit: cover;
    width: 100%;
    border-radius: 5px;
  }
  
  @media screen and (min-width: 768px) {
    .gallery {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media screen and (min-width: 1024px) {
    .gallery {
      grid-template-columns: repeat(3, 1fr);
    }
    .gallery img:nth-of-type(3) {
      grid-column: 2 / 4;
      grid-row: 1 / 3;
    }
    .gallery img:nth-of-type(9) {
      grid-column: 1 / 3;
      grid-row: 5 / 7;
    }
  }