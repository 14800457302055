.team-gallery {
    display: flex;
    flex-direction: column;
    margin: 4rem 2.4rem;    
    row-gap: 1.6rem;    
}
.mrgB-60 {
    margin-bottom: 0;
}

.team-gallery .item-team {
    height: 50rem;
    border-radius: 2rem;
}
.team1 {
    background-image: url('https://i.postimg.cc/R0DHN50S/PHOTO-2023-02-24-16-08-11.jpg');
}
.team2 {
    background-image: url('https://i.postimg.cc/V60btGSj/PHOTO-2023-02-24-16-07-25.jpg');
}
.team3 {
    background-image: url('https://i.postimg.cc/Gtk874xt/PHOTO-2023-02-24-16-06-48.jpg');
}
.team4 {
    background-image: url('https://i.postimg.cc/T185JVmB/PHOTO-2023-02-24-16-07-54.jpg');
}
.team5 {
    background-image: url('https://i.postimg.cc/Y08n11TF/dulce.jpg');
}
.item-team {
    background-size: cover;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 2rem;
    width: auto;
}
.item-team div {
    background: rgba(239, 235, 235, 0.6);
    border-radius: 1.4rem;
    margin-bottom: 3rem;
}
.item-team  p {
    font-size: 1.6rem;
    font-weight: 400;
    color: #3d4141;
    text-align: justify;    
    margin: 0;
    padding: 0 1.2rem;
}
.team-quote {
    font-size: 1.2rem;
    font-weight: 400;
    color: #3d4141;
    text-align: center;    
    margin: 0;
    padding: 2rem 0;
}

.item-team h2 {
    font-size: 1.8rem;
    font-weight: 800;
    color: #3d4141;
    text-align: center;    
    margin: 0;
}

@media screen and (min-width: 800px) {
    .team-gallery {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        column-gap: 2rem;
    }
    .team-gallery .item-team {
        height: 80rem;
        width: 50rem;        
    }
    .item-team p {
        font-size: 2.2rem;
        margin: 2rem 0;
        padding: 0 2rem;
    }    
    .item-team h2 {
        font-size: 2.2rem;
        margin: 2rem 0;
    }  
    .team-quote {
        font-size: 1.4rem;
    }  
    .item-team {
        padding-bottom: 5rem;
    }
    .mrgB-60 {
        margin-bottom: 14rem;
    }
    .item-team div {
        background: rgba(239, 235, 235, 0.6);
        border-radius: 1.4rem;
        height: 40rem;
        margin-bottom: 0;
    }
}