.footer-container {
    width: 100%;
    background: #ef8c22;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    row-gap: 3rem;
    margin-top: 2.4rem;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 2.4rem 0;
}
.flex-items-social {
    text-align: center;
    color: #0774f6;
    font-family: 'Manrope', sans-serif;;
}
.icons-social {
    color: #0774f6;
    font-size: 5rem;
    cursor: pointer;
}

.contact-container {
    width: 88%;
    height: 38rem;
    background: rgba(252, 203, 44, 1);
    border-radius: 2rem;
    box-shadow: 2px 4px 9px rgba(243, 235, 235, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.contact-container h2 {
    font-size: 2.8rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #0774f6;
    text-align: center;
    margin: 0;
    letter-spacing: 1px;
    font-family: 'Manrope', sans-serif;
}
.contact-container p {
    font-size: 1.8rem;
    font-weight: 400;
    text-transform: capitalize;
    color: #0774f6;
    text-align: center;
    margin: 0;
    font-family: 'Manrope', sans-serif;
}
.contact-container p span {
    font-size: 1.6rem;
    text-transform: lowercase;
}
.contact-container a {
    font-size: 1.8rem;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    color: #0774f6;
    text-align: center;
    font-family: 'Manrope', sans-serif;
}
.img-qr {
    width: 40%;
}
@media screen and (min-width: 800px) {
    .footer-container {
        display: grid;
        place-self: center;    
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 1rem;      
        row-gap: 0;  
    }
    .flex-items-social {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        row-gap: 1rem;
    }
    .icons-social {
        color: #0774f6;
        font-size: 5rem;
    }
    
    .contact-container {
        width: 100%;
        min-width: 50rem;
        height: 18rem;      
        position: relative; 
        bottom: 11rem;
        border-radius: 2rem;                
        justify-content: center;
    }
    .contact-container p span {
        font-size: 2rem;
    }
    .contact-container span {
        font-size: 2rem;
    }
}