@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');


.container {
  max-width: 84%;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 12rem;
  background-color: #fef7e5;
  position: relative;  
}

.logo {
  width: 20rem;
  height: 18rem;
} 

.menu-icon {
  display: none;
}

 /* .nav-elements {
  font-family: 'Poppins', sans-serif;
}  */

 .nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

 .nav-elements ul a {
  font-size: 1.8rem;
  font-weight: 600;
  color: #2f234f;
  text-decoration: none;
  letter-spacing: 1.5px;
} 

 .nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
} 

/* My styles */

.item-txt {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 800;
  color: #574c4c;
  text-transform: capitalize;
  text-align: justify;
}
.icon-paw {
  font-size: 4rem;
  color: darkorange;
  cursor: pointer;
}

.menu-text {
  font-size: 1.4rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: darkorange;
}
/* My styles end*/

@media (max-width: 800px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 800px) {
  .menu-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    padding-top: 1.6rem;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #fef7e5;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    height: 50%;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
  .navbar {
    height: 12rem;      
  }
  .logo {
    width: 17rem;
    height: 15rem;
  } 
}
