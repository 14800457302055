.modal-container {    
    position: absolute;
    top: 25%;
    border-radius: 1.4rem;
    margin: 0 0.5rem;
}
.modal-container img {
    width: 100%;
    border-radius: 1.4rem;
}
.modal-btn {
    background: none;
    border: none;
    color: #966CF0;
    font-size: 4rem;    
    position: absolute;
    left: 2%;
    top: -10%;
    margin: 0;        
}

@media screen and (min-width: 450px) {
    .modal-btn {
        background: none;
        border: none;
        color: #966CF0;
        font-size: 6rem;    
        position: absolute;
        left: 4%;
        top: -7%;
        margin: 0;        
    }
}

@media screen and (min-width: 800px) {
    .modal-container {
        width: 80rem;        
        position: absolute;
        left: 25%;
        top: 12%;
        border-radius: 1.4rem;
    }
    .modal-container img {
        width: 100%;
        border-radius: 1.4rem;
    }
    .modal-btn {
        background: none;
        border: none;
        color: #966CF0;
        font-size: 6rem;    
        position: absolute;
        left: 4%;
        top: -8%;
        margin: 0;        
    }    
}