.grid-center {
    display: grid;
    width: 80%;
    margin: 0 auto;
    height: 118rem;
}
.grid-3-items {
    width: 100%;
    height: 60rem;
    text-align: center;
}

.item-1 {
    background-image: url('https://i.postimg.cc/6pkqJx24/galeria-home-gato.jpg');
    background-size: cover;
    width: 100%;
    height: 60%;    
    border-radius: 0.6rem;
    margin-bottom: 2.4rem;    
}

.item-2 {
    background-image: url('https://i.postimg.cc/pLhr6LTt/galeria-home-gato-contacto.jpg');
    background-size: cover;
    width: 100%;
    height: 60%;    
    background-position: 30%;
    border-radius: 0.6rem;
    margin-bottom: 2.4rem;
}

.item-3 {
    background-image: url('https://i.postimg.cc/LsjXmQLC/galeria-home-perro.jpg');
    background-size: cover;
    width: 100%;
    height: 60%;    
    background-position: 35%;
    border-radius: 0.6rem;
}

.anch-txt-1 {
    text-align: center;
    color: #f3efeb;
    position: relative;
    top: 13rem;
    font-size: 3rem;
    letter-spacing: 1px;
    line-height: 4rem;
    text-shadow: 1px 1px 1px #919191,    
}
.anch-txt-2 {
    text-align: center;
    color: #f3efeb;
    position: relative;
    top: 13rem;
    font-size: 3rem;
    letter-spacing: 1px;
    line-height: 4rem;
    text-shadow: 2px 6px 5px #000000
}
.anch-txt-3 {
    text-align: center;
    color: #f3efeb;
    position: relative;
    top: 12rem;
    font-size: 3rem;
    letter-spacing: 1px;
    line-height: 4rem;
    text-shadow: 0px 2px 6px #000000
}


@media screen and (min-width: 800px) {
    .grid-center {
        display: grid;
        width: 80%;
        height: auto;
        margin-bottom: 12rem;
    }
    .grid-3-items {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2.2rem 1.4rem;
        place-self: center;
        place-items: center;
        width: 90rem;
        height: 70rem;
        text-align: center;
    }
    
    .item-1 {
        grid-column: 1 / 2;
        grid-row: 1 / 5;
        background-image: url('https://i.postimg.cc/6pkqJx24/galeria-home-gato.jpg');
        background-size: cover;
        width: 100%;
        height: 100%;
        border-radius: 0.6rem;    
        filter: grayscale(85%);
        margin-bottom: 0;
    }
    .item-1:hover {
        filter: grayscale(0);
        transition: filter 3.3s;
    }
    
    .item-2 {
        grid-row: 1 / 3;
        background-image: url('https://i.postimg.cc/pLhr6LTt/galeria-home-gato-contacto.jpg');
        background-size: cover;
        width: 100%;
        height: 100%;
        border-radius: 0.6rem;
        filter: grayscale(85%);
        margin-bottom: 0;
    }
    .item-2:hover {
        filter: grayscale(0);
        transition: filter 3.3s;
    }
    .item-3 {
        grid-row: 3 / 5;
        background-image: url('https://i.postimg.cc/LsjXmQLC/galeria-home-perro.jpg');
        background-size: cover;
        width: 100%;
        height: 100%;
        border-radius: 0.6rem;
        filter: grayscale(85%);
    }
    .item-3:hover {
        filter: grayscale(0);
        transition: filter 3.3s;
    }
    .anch-txt-1 {
        top: 27rem;   
    }
}