@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Epilogue:wght@200&family=Festive&family=Ibarra+Real+Nova&family=Manrope:wght@300&family=Plus+Jakarta+Sans&family=Public+Sans:wght@200&family=Redressed&family=Roboto&display=swap');

:root {
  /*background: rgb(174,232,237);*/
  background-color: #e5e5f7;
  opacity: 1;
  background-image:  linear-gradient(135deg, #f7c545 25%, transparent 25%), linear-gradient(225deg, #f7c545 25%, transparent 25%), linear-gradient(45deg, #f7c545 25%, transparent 25%), linear-gradient(315deg, #f7c545 25%, #e5e5f7 25%);
  background-position:  10px 0, 10px 0, 0 0, 0 0;
  background-size: 20px 20px;
  background-repeat: repeat;
 }
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-family: 'Manrope', sans-serif;
}

html {
  font-size: 62.5%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
