.tips-container {
    display: flex;   
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    row-gap: 2.4rem;
    margin: 0 1.6rem;
}
.tips-card {
    width: 100%;
    height: 28rem;
    border: 1px solid rgb(112, 179, 152);
    background: rgb(95,249,188);
    border-radius: 1.4rem;
    box-shadow: 2px 1px 4px rgba(10,10,10,1);
    padding-top: 2.4rem;
}
.text-big {
    text-align: center;
    font-size: 2.2rem;
    font-weight: 700;
    letter-spacing: 2px;
    color: #333;
}
.text-md {
    font-size: 1.8rem;
    font-weight: 600;
    color: #333;
    text-align: justify;
    padding: 1.6rem 2.3rem;
    line-height: 2.4rem;
}
.btn-learn {
    padding: 1.4rem 1.6rem;
    font-weight: 700;
    font-size: 2rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: #f8930e;
    color: #333;
    width: 14rem;
    border-radius: 1.6rem;
    text-align: right;
}
.align-title {
    display: flex;
    column-gap: 0;
    justify-content: space-evenly;
    align-items: center;
}
.display-promo {
    width: 85%;
    margin: 0 auto;    
    height: auto;
}
.hero-pug {
    width: 100%;
}
.icon-animals {
    font-size: 4rem;
    color: rgb(150, 108, 240);
}
.hero-logo {
    width: 22rem;
    height: 20rem;
} 

@media screen and (min-width: 800px) {
    .tips-container { 
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        row-gap: 0;
        column-gap: 1.8rem;
        margin: 0 2.2rem;
    }
    .tips-card {
        width: 32%;        
    }
    .display-promo {
        width: 85%;
        margin: 0 auto;    
        height: auto;
    }
    
    .icon-animals {
        font-size: 5rem;
        color: rgb(150, 108, 240);
    }
    
    .hero-logo {
        width: 40rem;
        height: 38rem;
      }
}